// import lowerdev from '@/service/lowerdev'
import { post } from "@/utils/apiBase.js";
import { mapState } from "vuex";
export default {
  name: "orderRemark",
  data() {
    return {
      mes: ""
    };
  },
  components: {},
  watch: {},
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  props: ["orderType", "orderInfo", "orderId", "remark"],
  mounted() {
    this.$nextTick(() => {
      this.mes = this.remark;
    });
  },
  methods: {
    sub() {
      // console.log(this.orderInfo)
      const order = {
        url: "/apis/mdorder/set_order_remark",
        method: "post",
        props: {
          order_id: this.orderId,
          remark: this.mes,
          user_id: this.userId
        }
      };
      if (this.orderType == 'shop') {
        order.url = '/mall/order/remark';
      }
      post(order.url, order.props).then(() => {
        this.$emit("ok");
      });
    },
    dialogBack() {
      this.$emit("dialogBack");
    },
    op(type) {
      this.$emit("toOp", type);
    }
  }
};