import { env } from "md-base-tools/env";
import PaymethodList from '@@/pay/paymethodList.vue';
export default {
  name: "dialog_btn",
  data() {
    return {
      env,
      payType: ''
    };
  },
  components: {
    PaymethodList
  },
  watch: {},
  computed: {},
  props: ["orderInfo", "pay_resource", "list"],
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    setPayType(payType) {
      this.payType = payType;
    },
    close() {
      this.$emit("close");
    },
    clickfn(type) {
      // eslint-disable-next-line vue/no-mutating-props
      this.pay_resource = type;
    },
    payClick() {
      this.$emit("close");
      this.$emit("payClick", this.payType);
    }
  }
};