export default {
  name: 'payShow',
  data() {
    return {
      show: false,
      actionsList: []
    };
  },
  watch: {
    dialog_show: {
      handler(newVal) {
        this.show = newVal;
      }
    },
    dialog_btn: {
      handler(newVal) {
        if (newVal && newVal.length) {
          newVal.map(e => {
            e.name = e.title;
          });
        }
        this.actionsList = newVal;
      }
    }
  },
  computed: {},
  props: ['dialog_show', 'list', 'dialog_btn'],
  mounted() {
    if (this.dialog_btn && this.dialog_btn.length) {
      this.dialog_btn.map(e => {
        e.name = e.title;
      });
    }
    this.actionsList = this.dialog_btn;
    this.$nextTick(() => {});
  },
  methods: {
    close() {
      this.$emit('toParent');
    },
    op(type) {
      this.$emit('toOp', type.type);
    }
  }
};