// import { postOrder } from '@/utils/apiBase.js'
// import lowerdev from '@/service/lowerdev'

export default {
  name: 'refund',
  data() {
    return {
      show: false,
      dialog_btn: [{
        name: '时间周期太长,不想等了'
      }, {
        name: '订单信息填写错了'
      }, {
        name: '误下单'
      }, {
        name: '其他平台有更好的选择'
      }, {
        name: '项目负面信息影响'
      }, {
        name: '其他原因'
      }]
    };
  },
  watch: {
    orderRefundShow: {
      handler(newVal) {
        this.show = newVal;
      }
    }
  },
  computed: {},
  props: ['orderType', 'orderInfo', 'orderRefundShow'],
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    dialogBack() {
      this.$emit('dialogBack');
    },
    op(type) {
      this.$emit('toOp', type.name);
    }
  }
};