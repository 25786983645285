import { post, get } from "@/utils/apiBase.js";
// import lowerdev from '@/service/lowerdev'
// import { postOrder } from '@/utils/apiBase.js'
export default {
  name: "orderTel",
  data() {
    return {
      show: false,
      code_posi: 0,
      idx: 0,
      mobile: "",
      remake: "",
      codeList: countryCode.codeList,
      payList: countryCode.payList
    };
  },
  watch: {},
  computed: {},
  props: ["orderType", "orderId", "orderInfo"],
  mounted() {
    this.$nextTick(() => {
      get("/apis/mdorder/order_address_detail", {
        id: this.orderId,
        type: "order_id",
        pro_id: ""
      }).then(data => {
        this.mobile = data.data.address_info.address_mobile;
      });
    });
  },
  methods: {
    onClose() {},
    onCancel() {
      this.show = false;
    },
    onConfirm({
      selectedIndexes
    }) {
      this.show = false;
      this.idx = selectedIndexes[0];
    },
    sub() {
      const order = {
        url: "/apis/mdorder/order_modify_mobile",
        method: "post",
        props: {
          user_id: this.$store.state.user.userId,
          order_id: this.orderId,
          address_mobile: this.mobile,
          country_code: this.codeList[this.idx].id
        }
      };
      post(order.url, order.props).then(() => {
        // lowerdev.toast({ msg: '操作成功' })
        this.$emit("ok");
      });
    },
    dialogBack() {
      this.$emit("dialogBack");
    },
    op(type) {
      this.$emit("toOp", type);
    }
  }
};
const countryCode = {
  codeList: [{
    id: "86",
    name: "中国大陆"
  }, {
    id: "852",
    name: "中国香港"
  }, {
    id: "853",
    name: "中国澳门"
  }, {
    id: "886",
    name: "中国台湾"
  }, {
    id: "61",
    name: "澳大利亚"
  }, {
    id: "1",
    name: "加拿大"
  }, {
    id: "33",
    name: "法国"
  }, {
    id: "49",
    name: "德国"
  }, {
    id: "62",
    name: "印度尼西亚"
  }, {
    id: "39",
    name: "意大利"
  }, {
    id: "81",
    name: "日本"
  }, {
    id: "82",
    name: "韩国"
  }, {
    id: "60",
    name: "马来西亚"
  }, {
    id: "64",
    name: "新西兰"
  }, {
    id: "31",
    name: "荷兰"
  }, {
    id: "63",
    name: "菲律宾"
  }, {
    id: "7",
    name: "俄罗斯"
  }, {
    id: "65",
    name: "新加坡"
  }, {
    id: "46",
    name: "瑞典"
  }, {
    id: "66",
    name: "泰国"
  }, {
    id: "44",
    name: "英国"
  }, {
    id: "380",
    name: "乌克兰"
  }, {
    id: "1",
    name: "美国"
  }, {
    id: "84",
    name: "越南"
  }],
  payList: [{
    text: '+86  中国大陆',
    value: 86
  }, {
    text: "+852 中国香港",
    value: 852
  }, {
    text: "+853 中国澳门",
    value: 853
  }, {
    text: "+886 中国台湾",
    value: 886
  }, {
    text: "+61  澳大利亚",
    value: 61
  }, {
    text: "+1   加拿大",
    value: 1
  }, {
    text: "+33  法国",
    value: 33
  }, {
    text: "+49  德国",
    value: 49
  }, {
    text: "+62  印度尼西亚",
    value: 62
  }, {
    text: "+39  意大利",
    value: 39
  }, {
    text: "+81  日本",
    value: 81
  }, {
    text: "+82  韩国",
    value: 82
  }, {
    text: "+60  马来西亚",
    value: 60
  }, {
    text: "+64  新西兰",
    value: 64
  }, {
    text: "+31  荷兰",
    value: 31
  }, {
    text: "+63  菲利宾",
    value: 63
  }, {
    text: "+7   俄罗斯",
    value: 7
  }, {
    text: "+65  新加坡",
    value: 65
  }, {
    text: "+46  瑞典",
    value: 46
  }, {
    text: "+66  泰国",
    value: 66
  }, {
    text: "+44  英国",
    value: 44
  }, {
    text: "+380 乌克兰",
    value: 380
  }, {
    text: "+1   美国",
    value: 1
  }, {
    text: "+84  越南",
    value: 84
  }],
  payCode: [86, 852, 853, 886, 61, 1, 33, 49, 62, 39, 81, 82, 60, 64, 31, 63, 7, 65, 46, 66, 44, 380, 1, 84]
};