import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_action_sheet = _resolveComponent("van-action-sheet");
  return _openBlock(), _createBlock(_component_van_action_sheet, {
    show: $data.show,
    actions: $data.dialog_btn,
    onSelect: $options.op,
    onClose: $options.dialogBack,
    onCancel: $options.dialogBack,
    "close-on-click-overlay": true,
    "cancel-text": "取消"
  }, null, 8, ["show", "actions", "onSelect", "onClose", "onCancel"]);
}